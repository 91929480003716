<template>
  <base-modal>
    <h1>{{ tr('Add tools') }}</h1>
    <h2>{{ tr('Choose one or more tools to add:') }}</h2>
    <table class="outer-table">
      <tr class="outer-table">
        <td class="left-outer-column">
          <table class="inner-table">
            <tr v-for="tool in toolsCol1" :key="tool.id" class="mdc-data-table__row">
              <td class="mdc-data-table__cell mdc-data-table__cell--checkbox">
                <check-box extra-class="tool-checkbox" :id="tool.id" :is-checked="false">
                </check-box>
              </td>
              <td class="mdc-data-table__cell tool-name" :style="labelStyle">
                <label :for="tool.id" :class="tool.svgIconBase64 ? '' : 'label-without-icon'" :style="fontStyle">
                  <span v-if="tool.svgIconBase64" class="tool-icon" v-html="base64Decode(tool.svgIconBase64)"
                    :style="iconStyle"></span>
                  {{ tool.name[language] }}
                  <span v-if="tool.description && tool.description[language]" class="dashboard-tip">
                    <info-circle></info-circle>
                    <p :style="tipParagraphStyle">{{ tool.description[language] }}</p>
                  </span>
                </label>
              </td>
            </tr>
          </table>
        </td>
        <td>
          <table class="inner-table">
            <tr v-for="tool in toolsCol2" :key="tool.id" class="mdc-data-table__row">
              <td class="mdc-data-table__cell mdc-data-table__cell--checkbox">
                <check-box extra-class="tool-checkbox" :id="tool.id" :is-checked="false">
                </check-box>
              </td>
              <td class="mdc-data-table__cell tool-name" :style="labelStyle">
                <label :for="tool.id" :class="tool.svgIconBase64 ? '' : 'label-without-icon'" :style="fontStyle">
                  <span v-if="tool.svgIconBase64" class="tool-icon" v-html="base64Decode(tool.svgIconBase64)"
                    :style="iconStyle"></span>
                  {{ tool.name[language] }}
                  <span v-if="tool.description && tool.description[language]" class="dashboard-tip dashboard-tip-left">
                    <info-circle></info-circle>
                    <p :style="tipParagraphStyle">{{ tool.description[language] }}</p>
                  </span>
                </label>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>

    <div class="modal-footer" :style="footerStyle">
      <button class="modal-button cancel-button" @click.prevent="onCancel" :style="buttonStyle">{{ tr('Cancel')
      }}</button>
      <button class="modal-button add-button" @click.prevent="onAddTools" :style="buttonPrimaryStyle">{{ tr('Add tools')
      }}</button>
    </div>
  </base-modal>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject } from 'vue'
import { useStore } from 'vuex'
import { v4 as uuidv4 } from 'uuid'

import BaseModal from '@/components/ui/BaseModal.vue'
import CheckBox from '@/components/ui/CheckBox/CheckBox.vue'
import { refreshToolPositions } from '@/components/ui/ToolManager/rearrange.js'
import { base64Decode, isThemeLight, themeColors, tipParagraphStyleFunc } from '@/common/shared.js'
import InfoCircle from '@/components/ui/icons/InfoCircle.vue'

export default {
  components: {
    BaseModal,
    CheckBox,
    InfoCircle,
  },
  setup(_, context) {
    const store = useStore()
    const tr = inject('tr')
    // const tutorialNext = inject('tutorialNext')

    const tools = computed(() => store.getters.sessionTools)
    const language = computed(() => store.getters.profile.language)
    const toolsMap = computed(() => {
      return store.getters.sessionTools.reduce((acc, t) => {
        acc[t.id] = t
        return acc
      }, {})
    })

    const onAddTools = () => {
      const checkboxes = document.querySelectorAll('.tool-checkbox:checked')
      const toolIds = Array.from(checkboxes).map((el) => el.id)
      const toolsToAdd = toolIds.map((id) => toolsMap.value[id])
      toolsToAdd.forEach((t) => {
        const tool = JSON.parse(JSON.stringify(t))
        tool.id = uuidv4()
        tool.col = 0
        tool.row = 0
        tool.width = tool.width || 12
        tool.height = tool.height || 5
        store.dispatch('addToolAtIndex', { tool, toolIndex: 0 })
        refreshToolPositions()
      })
      context.emit('close')
      // setTimeout(tutorialNext, 300)  // Required to allow modal to animate into place.
    }

    const onCancel = () => {
      context.emit('close')
    }

    const toolsCol1 = computed(() => {
      return tools.value.filter((_, index) => index % 2 === 0)
    })
    const toolsCol2 = computed(() => {
      return tools.value.filter((_, index) => index % 2 !== 0)
    })

    const buttonStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].toolInputBackgroundColor,
        borderColor: themeColors[store.getters.currentThemeName].modalTextColor,
        color: themeColors[store.getters.currentThemeName].modalTextColor,
      }
    })

    const buttonPrimaryStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].buttonBorderColor,
        color: themeColors[store.getters.currentThemeName].modalBackgroundColor,
        border: `${themeColors[store.getters.currentThemeName].buttonBorderColor}`,
      }
    })

    const footerStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].modalBackgroundColor,
      }
    })

    const labelStyle = computed(() => {
      return {
        color: themeColors[store.getters.currentThemeName].modalTextColor,
      }
    })

    const displayColor = computed(() => store.getters.checkboxColor)

    const iconStyle = computed(() => {
      return {
        fill: themeColors[store.getters.currentThemeName].modalIconColor,
      }
    })

    const checkboxOutline = computed(() => {
      return {
        outlineColor: isThemeLight[store.getters.currentThemeName] ? '#000' : '#fff',
      }
    })

    const tipParagraphStyle = computed(() => tipParagraphStyleFunc(store))

    const fontStyle = computed(() => {
      return {
        fontFamily: store.getters.font,
      }
    })

    return {
      base64Decode,
      buttonStyle,
      buttonPrimaryStyle,
      checkboxOutline,
      displayColor,
      fontStyle,
      footerStyle,
      iconStyle,
      labelStyle,
      language,
      onAddTools,
      onCancel,
      tipParagraphStyle,
      toolsCol1,
      toolsCol2,
      tr,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/pages/Dashboard/tables.scss';

h1 {
  font-size: 2rem;
  padding-top: 1rem;
}

h2 {
  font-size: 1.5rem;
}

table.outer-table {
  max-width: 100%;
  margin: 2rem auto 0 auto;
}

table.inner-table {
  border-collapse: collapse;
}

tr.outer-table {
  display: inline-flex;
}

tr.mdc-data-table__row {
  border: none;
}

.left-outer-column {
  padding-right: 3rem;
}

.tool-name {
  text-align: left;
  font-size: 1em;
  padding-left: 0;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

label {
  cursor: pointer;
  display: block;
  padding-top: 0.3rem;
}

.label-without-icon {
  padding-top: 0.3rem;
}

.modal-button {
  font: inherit;
  margin: 1rem 3rem;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1em;
  border-radius: 40px;


  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  }
}

:focus {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

:focus:not(:focus-visible) {
  transform: scale(1);
}

.modal-footer {
  margin: 0;
  position: sticky;
  bottom: 0;
}

.cancel-button {
  position: sticky;
  right: 100%;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  }
}

.add-button {
  position: sticky;
  left: 100%;
}

.tool-icon {
  display: inline-block;
  width: 24px;
  vertical-align: middle;
}
</style>